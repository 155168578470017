import axios, { AxiosError } from 'axios';
import { compare } from 'compare-versions';
import { ToolsLicense, LicenseStatus } from './license.model';
import { useQuery } from 'react-query';

function getWindowsDomain() {
  let returnUrl = '';
  if (window.location.origin.includes('tgcloud-dev.com')) {
    returnUrl = 'tgcloud-dev.com';
  } else if (window.location.origin.includes('tgcloud.io')) {
    returnUrl = 'tgcloud.io';
  }
  return returnUrl;
}

export interface AppLicense {
  instanceLicenseStatus: LicenseStatus;
  disableGraphStudio: boolean;
  disableInsights: boolean;
  disableGSQLShell: boolean;
  disableGraphQL: boolean;
}

interface Response<T> {
  error: boolean;
  message: string;
  results: T;
}

const licenseQuery = `
  query {
    License {
      Issuer
      Audience
      EndTime
      IssueTime
      Edition
      Host{
        MaxCPUCore
        MaxPhysicalMemoryBytes
        MaxClusterNodeNumber
      }
      Topology{
        MaxVertexNumber
        MaxEdgeNumber
        MaxGraphNumber
        MaxTopologyBytes
      }
      GST{
        Enable
        ZoomChartsLicense
      }
      Insights{
        Enable
      }
      GSQLShell{
        Enable
      }
      GraphQL{
        Enable
      }
      RuntimeMemory{
        MaxUserResidentSetBytes
      }
      Status
    }
  }
  `;

export function isCloud() {
  return window.location.origin.includes('tgcloud-dev.com') || window.location.origin.includes('tgcloud.io');
}

function useInstanceLicense(disabled: boolean) {
  const { data: version } = useQuery(
    ['version'],
    async () => {
      const response = await axios.get<Response<{ tigergraph_version: string }>>('/api/version');
      return response.data.results.tigergraph_version;
    },
    {
      enabled: !disabled,
    }
  );

  // for version <= 3.9.2
  // 1 the graphql api do not support the new tools app license
  // 2 and no need to enforce license on old instance
  const shouldFetchLicense = version !== undefined && compare(version, '3.9.3', '>=');

  const { data: license, refetch } = useQuery<ToolsLicense, AxiosError>(
    ['license'],
    async () => {
      const response = await axios.post(`/api/v2`, { query: licenseQuery });
      return response.data.data.License;
    },
    {
      enabled: shouldFetchLicense && !disabled,
    }
  );

  let disableGraphStudio = false;
  if (license?.GST?.Enable === false) {
    disableGraphStudio = true;
  }
  let disableInsights = false;
  if (license?.Insights?.Enable === false) {
    disableInsights = true;
  }
  let disableGSQLShell = false;
  if (license?.GSQLShell?.Enable === false) {
    disableGSQLShell = true;
  }
  let disableGraphQL = false;
  if (license?.GraphQL?.Enable === false) {
    disableGraphQL = true;
  }

  let instanceLicenseStatus = LicenseStatus.Valid;
  if (license) {
    instanceLicenseStatus = license.Status;
  }

  return {
    license: {
      instanceLicenseStatus,
      disableGraphStudio,
      disableInsights,
      disableGSQLShell,
      disableGraphQL,
    },
    refetchLicense: shouldFetchLicense && !disabled ? refetch : () => {},
  };
}

function useCloudLicense(disabled: boolean) {
  const { data: license, refetch } = useQuery<
    {
      enable_graphstudio?: boolean;
      enable_insights?: boolean;
      enable_gsqlshell?: boolean;
      enable_graphql?: boolean;
    },
    AxiosError
  >(
    ['license'],
    async () => {
      const response = await axios.get(`https://api.${getWindowsDomain()}/orgs/self`);
      return response.data.org_settings;
    },
    {
      enabled: !disabled,
    }
  );

  let disableGraphStudio = false;
  if (license && license.enable_graphstudio === false) {
    disableGraphStudio = true;
  }
  let disableInsights = false;
  if (license && license.enable_insights === false) {
    disableInsights = true;
  }
  let disableGSQLShell = false;
  if (license && license.enable_gsqlshell === false) {
    disableGSQLShell = true;
  }
  let disableGraphQL = false;
  if (license && license.enable_graphql === false) {
    disableGraphQL = true;
  }

  let instanceLicenseStatus = LicenseStatus.Valid;

  return {
    license: {
      instanceLicenseStatus,
      disableGraphStudio,
      disableInsights,
      disableGSQLShell,
      disableGraphQL,
    },
    refetchLicense: refetch,
  };
}

export function useLicense() {
  const isCloudVersion = isCloud();
  const instanceLicense = useInstanceLicense(isCloudVersion);
  const cloudInstance = useCloudLicense(!isCloudVersion);
  return isCloudVersion ? cloudInstance : instanceLicense;
}
