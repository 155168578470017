import { useEffect } from 'react';
export function ToolHead({
  appname,
  appicon,
  innerapp,
  user,
}: {
  appname: string;
  appicon: string;
  innerapp: string;
  user: string;
}) {
  useEffect(() => {
    const toolsHead = document.querySelector('tools-head');
    // @ts-ignore
    toolsHead.appName = appname;
    // @ts-ignore
    toolsHead.appIcon = appicon;
    // @ts-ignore
    toolsHead.innerApp = innerapp;
    // @ts-ignore
    toolsHead.userName = user;
  }, [appicon, appname, innerapp, user]);
  // @ts-ignore
  return <tools-head />;
}
